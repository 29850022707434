.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.TopNews {
  position: fixed;
  bottom:0%;
  z-index: 99;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: -moz-linear-gradient(0% 50% 0deg,#254282 0%, #2B99FC 100%);
  background: -webkit-linear-gradient(0deg,#254282 0%, #2B99FC 100%);
  background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0, rgba(45, 154, 192, 1)), color-stop(1, rgba(45, 154, 192, 1)));
  background: -o-linear-gradient(0deg,#254282 0%, #2B99FC 100%);
  background: -ms-linear-gradient(0deg,#254282 0%, #2B99FC 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#2D9AC0', endColorstr='#6CE8AB' ,GradientType=0)";
  background: linear-gradient(90deg,#254282 0%, #2B99FC 100%);
  width: 100%;
  opacity: 1;
  height: 56px;
  -webkit-transition: all 1s Linear;
  -moz-transition: all 1s Linear;
  -o-transition: all 1s Linear;
  -ms-transition: all 1s Linear;
}

.TopNews a {
  color: white;
    font-weight: bold;
    text-decoration: underline;
}